import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "../../store/index";
import client from "../feathers";
import { Paginated } from "@feathersjs/feathers";

interface SettingsState {
  values: {
    [key: string]: Setting;
  };
  loading: "idle" | "pending" | "succeeded" | "failed";
}

const initialState: SettingsState = {
  values: {},
  loading: "idle",
};
const omit = (prop: any, { [prop]: _, ...rest }) => rest;
export function initSettings(): AppThunk {
  return (dispatch) => {
    const settings = client.service("settings");
    const {
      updateSetting,
      removeSetting,
      clearSettings,
    } = settingsSlice.actions;
    settings.on("created", (s) => dispatch(updateSetting(s)));
    settings.on("patched", (s) => dispatch(updateSetting(s)));
    settings.on("updated", (s) => dispatch(updateSetting(s)));
    settings.on("removed", (s) => dispatch(removeSetting(s.name)));

    // On successfull login
    client.on("authenticated", (_login) => {
      dispatch(clearSettings());
      dispatch(fetchSettings());
    });

    // On logout reset all all local state (which will then show the login screen)
    client.on("logout", () => {
      dispatch(clearSettings());
    });
  };
}
export const fetchSettings = createAsyncThunk(
  "settings/fetchSettings",
  async () => {
    const settings = client.service("settings");

    // Get all settings
    var settingsPage = await settings.find();
    return (settingsPage as Paginated<Setting>).data;
  }
);

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    updateSetting: (state, action: PayloadAction<Setting>) => {
      state.values = { ...state.values, [action.payload.name]: action.payload };
    },
    removeSetting: (state, action: PayloadAction<string>) => {
      state.values = omit(action.payload, state.values);
    },
    clearSettings: (state) => {
      state.values = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSettings.pending, (state) => {
      state.loading = "pending";
    });
    // omit posts loading reducers
    builder.addCase(fetchSettings.fulfilled, (state, { payload }) => {
      // We can directly add the new post object to our posts array
      state.values = Object.fromEntries(
        payload.map((s: Setting) => [s.name, s])
      );
      state.loading = "succeeded";
    });
  },
});

export function removeSettingAsync(id: number): AppThunk<Promise<Setting>> {
  return (_dispatch) => client.service("settings").remove(id);
}

export function updateSettingAsync(
  id: number,
  value: string
): AppThunk<Promise<Setting>> {
  return (_dispatch) => client.service("settings").patch(id, { value });
}

export function createSettingAsync(
  name: string,
  value: string
): AppThunk<Promise<Setting>> {
  return (_dispatch) => client.service("settings").create({ name, value });
}

export const selectSettings = (state: RootState) => state.settings.values;
export const selectSetting = (state: RootState, name: string) =>
  state.settings.values[name];

export default settingsSlice.reducer;
