import React, { Component } from "react";
import videojs  from "video.js/dist/alt/video.core.novtt";
import "video.js/dist/video-js.css";

interface Props {
  type: string;
  source: string;
  posterUrl?: string;
}

export default class VideoPlayer extends Component<Props> {
  videoNode = React.createRef<HTMLVideoElement>();
  player?: videojs.Player = undefined;

  componentDidMount = (): void => {
    // instantiate Video.js
    import("video.js/dist/alt/video.core.novtt").then((m) => {
      this.player = m.default(this.videoNode.current, {}, () => {
        console.log("onPlayerReady");
        this.player?.src({ type: this.props.type, src: this.props.source });
        this.player?.load();
      });
    });
  };

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  render() {
    return (
      <div data-vjs-player>
        <video
          ref={this.videoNode}
          poster={this.props.posterUrl}
          className="video-js full-page"
          controls
          preload="auto"
        ></video>
      </div>
    );
  }
}
