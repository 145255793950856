import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "../../store/index";
import client from "../feathers";
import { Paginated } from "@feathersjs/feathers";

interface ChatState {
  messages: Message[];
  totalMessageCount: number;
  loading: "idle" | "pending" | "succeeded" | "failed";
}

const initialState: ChatState = {
  messages: [],
  totalMessageCount: 0,
  loading: "idle",
};

export function initMessages(): AppThunk {
  return (dispatch) => {

    const {addMessage, updateMessage, removeMessage, clearMessages} = chatSlice.actions;

    const messages = client.service("messages");
    messages.on("created", (m: Message) => dispatch(addMessage(m)));
    messages.on("patched", (m: Message) => dispatch(updateMessage(m)));
    messages.on("updated", (m: Message) => dispatch(updateMessage(m)));
    messages.on("removed", (m: Message) => dispatch(removeMessage(m.id)));

    // On successfull login
    client.on("authenticated", (_login) => {
      dispatch(clearMessages());
      dispatch(fetchMessages());
    });

    // On logout reset all all local state (which will then show the login screen)
    client.on("logout", () => {
      dispatch(clearMessages());
    });
  };
}

export const fetchMessages = createAsyncThunk(
  "chat/fetchMessages",
  async () => {
    const messageService = client.service("messages");
    const messagePage = await messageService.find({
      query: {
        $sort: { createdAt: -1 },
        $limit: 25,
      },
    });

    // Once both return, update the state
    return messagePage as Paginated<Message>;
  }
);

export const createMessage = createAsyncThunk(
  "chat/createMessage",
  async (props: { text: string; color: string }) => {
    return await client
      .service("messages")
      .create({ text: props.text, color: props.color });
  }
);

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    addMessage: (state, action: PayloadAction<Message>) => {
      state.messages = [...state.messages, action.payload];
      state.totalMessageCount++;
    },
    updateMessage: (state, action: PayloadAction<Message>) => {
      state.messages = state.messages.map((m) =>
        m.id === action.payload.id ? action.payload : m
      );
    },
    removeMessage: (state, action: PayloadAction<number>) => {
      state.messages = state.messages.filter(
        (message) => message.id !== action.payload
      );
      state.totalMessageCount--;
    },
    clearMessages: (state) => {
      state.messages = [];
      state.totalMessageCount = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMessages.pending, (state, _action) => {
      state.loading = "pending";
    });
    // omit posts loading reducers
    builder.addCase(fetchMessages.fulfilled, (state, { payload }) => {
      // We can directly add the new post object to our posts array
      state.messages = payload.data.reverse();
      state.totalMessageCount = payload.total;
      state.loading = "succeeded";
    });
  },
});


export function deleteAllMessages(): AppThunk {
  return (_dispatch) => {
    let messagesService = client.service("messages");
    return messagesService.remove(null, { query: {} });
  };
}

export const selectMessages = (state: RootState) => state.chat.messages;
export const selectMessageCount = (state: RootState) =>
  state.chat.totalMessageCount;

export default chatSlice.reducer;
