import React from "react";
import { MentionsInput, Mention, OnChangeHandlerFunc, SuggestionDataItem } from "react-mentions";

const chatStyles = {
  highlighter: {
    border: 0,
  },
  input: {
    border: 0,
  },

  suggestions: {
    list: {
      backgroundColor: "var(--dark)",
      border: "1px solid rgba(1,1,1,1)",
      fontSize: 14,
    },
    item: {
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.15)",
      "&focused": {
        backgroundColor: "var(--gray)",
        // border: "1px dashed"
      },
    },
  },
};
const mentionStyle = {
  backgroundColor: "var(--gray)",
};
function ChatInput(props: {
  message: string;
  color: string;
  onChange?: OnChangeHandlerFunc;
  onSendMessage?: () => void;
  users: SuggestionDataItem[];
}) {

  const sendMessage = (ev: React.FormEvent) => {
    ev.preventDefault();
    props.onSendMessage?.();
  };

  return (
    <form
      onSubmit={sendMessage}
      className="m-1 form-check p-2 d-flex flex-space-between"
      id="send-message"
    >
      <div className="input-group h-100">
        <MentionsInput
          className="mentions form-control"
          classNames={{
            mentions: "form-control input-dark",
            mentions__input: "form-control input-dark",
          }}
          value={props.message}
          singleLine={true}
          style={chatStyles}
          allowSpaceInQuery={true}
          allowSuggestionsAboveCursor={true}
          onChange={props.onChange}
        >
          <Mention
            trigger="@"
            data={props.users}
            displayTransform={(_id, display) => `@${display}`}
            markup="@[__display__](__id__)"
            
            className="rounded-sm"
            style={mentionStyle}
            appendSpaceOnAdd={true}
          />
        </MentionsInput>
        <div className="input-group-append h-100">
          <button
            type="submit"
            value="Send"
            className="px-4 input-dark h-100 btn btn-primary"
            style={{ background: props.color }}
          >
            <span>Send</span>
          </button>
        </div>
      </div>
    </form>
  );
}

export default ChatInput;
