/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteAllMessages, selectMessageCount } from "../features/chat/chatSlice";

function AdminMessages() {
  const messageCount = useSelector(selectMessageCount);
  const dispatch = useDispatch();

  return (
    <div>
      <span>Message Count: {messageCount}</span>
      <button onClick={() => dispatch(deleteAllMessages())} className="btn btn-primary col-4">
        Delete {messageCount} Messages
      </button>
    </div>
  );
}

export default AdminMessages;
