import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { RootState } from "./store";

import "./scss/custom.scss";

import Chat from "./ui/Chat";
import Login from "./ui/Login";
import Video from "./ui/Video";
import Admin from "./admin/Admin";
import Navigation from "./ui/Navigation";
import ChatOverlay from "./ui/ChatOverlay";
import PageHeader from "./ui/PageHeader";

const mapState = (state: RootState) => ({
  initialReauthPending: state.session.initialReauthPending,
  currentUser: state.session.currentUser,
  settings: state.settings,
});

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

class App extends Component<PropsFromRedux, {}> {
  render() {
    for (var s in this.props.settings.values) {
      const setting = this.props.settings.values[s];
      // console.log(setting);
      if (setting.name.startsWith("--")) {
        document.documentElement.style.setProperty(setting.name, setting.value);
      }
    }

    if (this.props.initialReauthPending) {
      return (
        <main className="container text-center">
          <PageHeader />
          <h1>Loading...</h1>
        </main>
      );
    } else if (this.props.currentUser) {
      return (
        <Router>
          <Switch>
            <Route path="/admin">
              <Navigation />
              <Admin />
            </Route>
            <Route path="/video">
              <Video />
              <ChatOverlay />
            </Route>
            <Route path="/video-overlay">
              <ChatOverlay />
            </Route>
            <Route path="/">
              <Navigation />
              <Chat />
            </Route>
          </Switch>
        </Router>
      );
    }

    return <Login />;
  }
}

export default connector(App);
