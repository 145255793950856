import React, { useState } from "react";
import { ChromePicker, ColorResult } from "react-color";
import "../../scss/color-picker.scss";

function ColorPicker(props: {
  color?: string;
  onColorChanged: (color: ColorResult) => void;
}) {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  return (
    <div className="colorPicker">
      <div
        className="colorPickerSwatch"
        onClick={() => setDisplayColorPicker(!displayColorPicker)}
      >
        <div
          className="colorPickerColor"
          style={{ background: `${props.color}` }}
        />
      </div>
      {displayColorPicker ? (
        <div className="colorPickerPopover">
          <div
            className="colorPickerCover"
            onClick={() => setDisplayColorPicker(false)}
          />
          <ChromePicker
            disableAlpha={true}
            color={props.color}
            onChange={props.onColorChanged}
          />
        </div>
      ) : null}
    </div>
  );
}

export default ColorPicker;
