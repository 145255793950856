/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, ChangeEvent } from "react";
import VideoPlayer from "./controls/VideoPlayer";

import { RootState } from "../store";
import { connect, ConnectedProps } from "react-redux";
import { selectSetting } from "../features/settings/settingsSlice";

const mapState = (state: RootState) => ({
  posterUrl: selectSetting(state, "posterUrl")
});

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface State {
  source?: string;
  type?: string;
}

class Video extends Component<PropsFromRedux, State> {
  constructor(props: PropsFromRedux) {
    super(props);
    this.state = {
      source: undefined,
      type: undefined,
    };
  }

  onFileChanged = (evt: ChangeEvent<HTMLInputElement>): void => {
    console.log("event", evt);
    let inputElement = evt.target;
    if (!inputElement) {
      console.log("Problem playing video, no control available");
      return;
    }

    if (!inputElement.files || inputElement.files.length === 0) {
      console.log("Problem playing video, null or empty files");
      return;
    }

    var file = inputElement.files[0];
    var URL = window.URL || window.webkitURL;
    var fileUrl = URL.createObjectURL(file);
    console.log(file);
    console.log(fileUrl);
    var type = file.type;
    this.setState({
      source: fileUrl,
      type: type,
    });

    console.log(this.state);
    // var canPlay = player.canPlayType(type);

    // var isError = canPlay === "";

    // if (isError) {
    //   return;
    // }

    // var fileURL = window.URL.createObjectURL(file);
    // var fileType = this.files[0].type;
    // console.log(fileURL);
    // player.src({ type: fileType, src: fileURL });
    // player.load();

    // var inputElem = document.querySelector("input");
    // if (inputElem && inputElem.parentElement) {
    //   inputElem.parentElement.removeChild(inputElem);
    // }
  };

  render() {
    return (
      <div className="full-page">
        {this.state.source === undefined && (
          <div>
            <input type="file" accept="video/*" onChange={this.onFileChanged} />
            <div className="p-1" style={{ textAlign: "center", fontSize: "6em" }}>
              <span className="font-title text-primary">
                <span className="text-secondary">Cinema</span> Riff
              </span>
            </div>
          </div>
        )}
        {this.state.source && (
          <VideoPlayer
            type={this.state.type ?? ""}
            source={this.state.source ?? ""}
            posterUrl={this.props.posterUrl?.value}
          />
        )}
      </div>
    );
  }
}

export default connector(Video);
