import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "../../store/index";
import client from "../feathers";
import { Paginated } from "@feathersjs/feathers";

interface UsersState {
  values: User[];
  loading: "idle" | "pending" | "succeeded" | "failed";
}

const initialState: UsersState = {
  values: [],
  loading: "idle",
};

export function initUsers(): AppThunk {
  return (dispatch, getState) => {
    let state = getState();
    if (state.users.loading !== "idle") {
      return;
    }

    const { addUser, updateUser, removeUser, clearUsers } = usersSlice.actions;

    const users = client.service("users");
    users.on("created", (u: User) => dispatch(addUser(u)));
    users.on("patched", (u: User) => dispatch(updateUser(u)));
    users.on("updated", (u: User) => dispatch(updateUser(u)));
    users.on("removed", (u: User) => dispatch(removeUser(u.id)));

    // On successfull login
    client.on("authenticated", (_login) => {
      dispatch(fetchUsers());
    });

    // On logout reset all all local state (which will then show the login screen)
    client.on("logout", () => {
      dispatch(clearUsers());
    });

    dispatch(fetchUsers());
  };
}
export const fetchUsers = createAsyncThunk("users/fetchUsers", async () => {
  const users = client.service("users");

  // Get all settings
  var usersPage = await users.find({
    query: {
      $sort: { createdAt: 1 },
      $limit: 200,
    },
  });
  return (usersPage as Paginated<User>).data;
});

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    addUser: (state, action: PayloadAction<User>) => {
      state.values = [...state.values, action.payload];
    },
    updateUser: (state, action: PayloadAction<User>) => {
      state.values = state.values.map((m) =>
        m.id === action.payload.id ? action.payload : m
      );
    },
    removeUser: (state, action: PayloadAction<number>) => {
      state.values = state.values.filter((user) => user.id !== action.payload);
    },
    clearUsers: (state) => {
      state.values = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.pending, (state, _action) => {
      state.loading = "pending";
    });
    // omit posts loading reducers
    builder.addCase(fetchUsers.fulfilled, (state, { payload }) => {
      // We can directly add the new post object to our posts array
      state.values = payload;
      state.loading = "succeeded";
    });
  },
});

export function removeUserAsync(id: number): AppThunk<Promise<User>> {
  return (_dispatch) => {
    return client.service("users").remove(id);
  };
}

export function updateUserAsync(
  id: number,
  user: Partial<User>
): AppThunk<Promise<User>> {
  return (_dispatch) => {
    return client.service("users").patch(id, user);
  };
}

export function createUserAsync(
  name: string,
  password: string = ""
): AppThunk<Promise<User>> {
  return (_dispatch) => {
    return client.service("users").create({ name: name, password });
  };
}

export const selectUsers = (state: RootState) => state.users.values;

export default usersSlice.reducer;
