import React, { useState } from "react";
import PageHeader from "./PageHeader";

import { RootState } from "../store";
import { useDispatch, useSelector } from "react-redux";
import { loginAsync } from "../features/session/sessionSlice";

function Login() {
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [hiddenClickCount, setHiddenClickCount] = useState(0);
  const dispatch = useDispatch();
  const signInError = useSelector(
    (state: RootState) => state.session.signInError
  );

  let login = () => {
    dispatch(loginAsync({ name, password }));
  };

  return (
    <main className="login container-sm" id="login">
      <PageHeader />

      <div className="row justify-content-center">
        <h2>Pick Your Name</h2>
      </div>
      <div className="row justify-content-center">
        <p className="text-danger">{signInError}</p>
      </div>

      <div className="row justify-content-center">
        <form className="col-md-6" onSubmit={() => login()}>
          <input
            className="form-control m-1"
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="text"
            name="name"
            placeholder="name"
          />
          {hiddenClickCount > 3 && (
            <input
              className="form-control m-1"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              name="password"
              placeholder="password"
            />
          )}

          <input
            id="loginbtn"
            type="submit"
            style={{ fontSize: "1.5rem" }}
            className="form-control btn btn-primary font-title m-1 h1"
            onClick={(event) => {
              event.preventDefault();
              login();
            }}
            value="Lets do this!"
          />
        </form>
      </div>

      <div
        className="fixed-bottom text-center text-muted"
        onClick={() => setHiddenClickCount(hiddenClickCount + 1)}
      >
        Built by @Projects
      </div>
    </main>
  );
}

export default Login;
