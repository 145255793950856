/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import ColorPicker from "../ui/controls/ColorPicker";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  selectUsers,
  initUsers,
  removeUserAsync,
  updateUserAsync,
  createUserAsync,
} from "../features/users/usersSlice";
import { selectCurrentUser } from "../features/session/sessionSlice";
import { useThunkDispatch } from "../store";

function userIsAdmin(user: User): boolean {
  return user.permissions.split(",").includes("admin");
}

function AdminUser({ user }: { user: User }) {
  const dispatch = useDispatch();
  const isAdmin = userIsAdmin(user);

  let toggleAdmin = () => {
    let perms = user.permissions.split(",");
    if (perms.includes("admin")) {
      perms = perms.filter((e) => e !== "admin");
    } else {
      perms = [...perms, "admin"];
    }

    dispatch(
      updateUserAsync(user.id, {
        permissions: perms.join(","),
      })
    );
  };

  return (
    <Row>
      <Col>{user.name}</Col>
      <Col>[{user.permissions}]</Col>
      <Col xs={2}>
        <button
          onClick={() => dispatch(removeUserAsync(user.id))}
          className="btn btn-primary"
        >
          Delete
        </button>
      </Col>
      <Col xs={2}>
        <button onClick={toggleAdmin} className="btn btn-primary">
          {isAdmin ? "-Admin" : "+Admin"}
        </button>
      </Col>
      <Col xs={1}>
        <ColorPicker
          color={user.color}
          onColorChanged={(c) =>
            dispatch(updateUserAsync(user.id, { color: c.hex }))
          }
        />
      </Col>
    </Row>
  );
}

function AdminUsers() {
  const dispatch = useThunkDispatch();
  useEffect(() => {
    dispatch(initUsers());
  }, [dispatch]);
  const users = useSelector(selectUsers);
  const current = useSelector(selectCurrentUser);

  let deleteOtherUsers = async () => {
    const toRemove = users.filter((u) => u.id !== current?.id).map((u) => u.id);
    toRemove.forEach(async (id: number) => {
      dispatch(removeUserAsync(id));
    });
  };

  let deleteNonadminUsers = async () => {
    const toRemove = users.filter((u) => !userIsAdmin(u)).map((u) => u.id);
    toRemove.forEach(async (id: number) => {
      dispatch(removeUserAsync(id));
    });
  };

  let createTestUser = () => {
    dispatch(createUserAsync("test" + Math.random()));
  };

  return (
    <div className="h-100 d-flex flex-column">
      <span>Current user: {current?.name}</span>
      <div className="d-flex">
        <button onClick={deleteOtherUsers} className="btn btn-primary">
          Delete All Other Users
        </button>
        <button onClick={deleteNonadminUsers} className="btn btn-primary">
          Delete Non-Admin Users
        </button>
        <button onClick={createTestUser} className="btn btn-primary">
          Create Test User
        </button>
      </div>

      <Container fluid={true} className="h-100 overflow-auto">
        {users.map((u) => (
          <AdminUser key={u.id} user={u}></AdminUser>
        ))}
      </Container>
      <div />
    </div>
  );
}

export default AdminUsers;
