import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import client from "../feathers";
import { AppThunk, RootState } from "../../store/index";

interface SessionState {
  initialReauthPending: boolean;
  currentUser?: User;
  color: string;
  signInError: string;
}

const initialState: SessionState = {
  initialReauthPending: true,
  currentUser: undefined,
  color: "red",
  signInError: "",
};

export function initSession(): AppThunk {
  return (dispatch) => {

    const {setCurrentUser, setUserColor} = sessionSlice.actions;

    // On successfull login
    client.on("authenticated", (login) => {
      const currentUser = login.user as User;
      dispatch(setCurrentUser(currentUser));
      dispatch(setUserColor(currentUser?.color));
    });

    // On logout reset all all local state (which will then show the login screen)
    client.on("logout", () => {
      dispatch(setCurrentUser(undefined));
    });

    dispatch(reauthenticate());
  };
}

export const reauthenticate = createAsyncThunk(
  "session/reauthenticate",
  async () => {
    await client.authenticate();
  }
);

async function login(name: string, password: string): Promise<void> {
  try {
    await client.authenticate({
      strategy: "local",
      name,
      password,
    });
    return;
  } catch (error) {
    try {
      await client.service("users").create({ name, password });
    } catch {
      // Rethrow the original error
      // TODO: Check if create failed because of dup, if not return create error
      throw error;
    }

    await await client.authenticate({
      strategy: "local",
      name,
      password,
    });
  }
}

export const loginAsync = createAsyncThunk(
  "session/login",
  async ({
    name,
    password,
  }: {
    name: string;
    password: string;
  }): Promise<void> => {
    await login(name, password);
  }
);

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    setUserColor: (state, action: PayloadAction<string>) => {
      state.color = action.payload;
    },
    setCurrentUser: (state, action: PayloadAction<User | undefined>) => {
      state.currentUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(reauthenticate.rejected, (state) => {
      state.initialReauthPending = false;
    });
    builder.addCase(reauthenticate.fulfilled, (state) => {
      state.initialReauthPending = false;
    });
    builder.addCase(loginAsync.fulfilled, (state) => {
      state.signInError = "";
    });
    builder.addCase(loginAsync.rejected, (state, action) => {
      state.signInError = action.error.message ?? "unknown error";
    });
  },
});

export const { setUserColor } = sessionSlice.actions;

export function logout(): AppThunk<ReturnType<typeof client.logout>> {
  return (_dispatch) => {
    return client.logout();
  };
}

export const selectCurrentUser = (state: RootState) =>
  state.session.currentUser;
export const selectCurrentUserIsAdmin = (state: RootState) =>
  state.session.currentUser?.permissions.includes("admin");
export default sessionSlice.reducer;
