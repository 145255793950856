/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Color from "color";

const regex = /@\[([^\]]+?)\]\(-?[0-9].*-(#[^)]+?)\)/g;

function ChatOverlayMessage(props: {
  message: Message;
}) {
  const userColor = {
    background: props.message.color,
  };

  let components: JSX.Element[] = [];
  let m;

  let pos = 0;
  let i = 0;
  let str = props.message.text;

  while ((m = regex.exec(str)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === regex.lastIndex) {
      regex.lastIndex++;
    }

    if (pos < m.index) {
      const unmatched = str.slice(pos, m.index);
      components.push(<span key={i++}>{unmatched}</span>);
    }
    pos = m.index + m[0].length;

    let nc = Color(m[2]).alpha(0.25).toString();
    components.push(
      <span key={i++} style={{ backgroundColor: nc }} className="px-0_5 rounded">
        @{m[1]}
      </span>
    );
  }
  const unmatched = str.slice(pos);
  components.push(<span key={i++}>{unmatched}</span>);

  return (
    <div className="d-flex flex-row overflow-hidden h1 chat-message fade-in-out">
      <span className="px-3 py-1 rounded-left message-header" style={userColor}>
        <span>
          {props.message.userName}
        </span>
      </span>
      <span className="px-3 py-1 rounded-right message-content">
        {components}
      </span>
    </div>
  );
}

export default ChatOverlayMessage;
