/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Button, InputGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  createSettingAsync,
  removeSettingAsync,
  selectSettings,
  updateSettingAsync,
} from "../features/settings/settingsSlice";
import { Add, Done, DeleteForever } from "@material-ui/icons";
import { useThunkDispatch } from "../store";

export function AdminSetting(props: { setting: Setting }) {
  const [pendingValue, setPendingValue] = useState(props.setting.value);
  const [modified, setModified] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    setModified(pendingValue !== props.setting.value);
  }, [props.setting.value, modified, pendingValue]);
  let updateSetting = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(updateSettingAsync(props.setting.id, pendingValue));
  };

  let deleteSetting = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch(removeSettingAsync(props.setting.id));
  };

  return (
    <div style={{ height: "3em" }}>
      <form
        onSubmit={updateSetting}
        className="m-1 form-check p-0 d-flex flex-space-between"
      >
        <InputGroup>
          <input
            type="text"
            name="text"
            className="form-control input-dark h-100"
            autoComplete="off"
            value={props.setting.name}
            disabled={true}
            width="20%"
          />
          <input
            type="text"
            name="text"
            className="form-control input-dark h-100"
            autoComplete="off"
            value={pendingValue}
            onChange={(v) => setPendingValue(v.target.value)}
            width="80%"
          />
          <InputGroup.Append className="h-100">
            <Button
              type="submit"
              value="Send"
              className="p-2 input-dark h-100"
              disabled={!modified}
            >
              <Done />
            </Button>
            <Button
              // type="submit"
              value="Delete"
              className="p-2 input-dark h-100"
              onClick={deleteSetting}
            >
              <DeleteForever />
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </form>
    </div>
  );
}

export function AdminSettings() {
  const settings = useSelector(selectSettings);
  const [pendingName, setPendingName] = useState("");
  const [pendingValue, setPendingValue] = useState("");
  const dispatch = useThunkDispatch();

  let addSetting = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(createSettingAsync(pendingName, pendingValue)).then(() => {
      setPendingName("");
      setPendingValue("");
    });
  };

  return (
    <div className="d-flex flex-column">
      {Object.values(settings).map((s) => (
        <AdminSetting key={s.id} setting={s} />
      ))}
      <div style={{ height: "3em" }}>
        <form
          onSubmit={addSetting}
          className="m-1 form-check p-0 d-flex flex-space-between"
        >
          <InputGroup>
            <input
              type="text"
              name="text"
              className="form-control input-dark h-100"
              autoComplete="off"
              value={pendingName}
              onChange={(v) => setPendingName(v.target.value)}
              placeholder="name"
            />
            <input
              type="text"
              name="text"
              className="form-control input-dark h-100"
              autoComplete="off"
              value={pendingValue}
              onChange={(v) => setPendingValue(v.target.value)}
              placeholder="value"
            />
            <InputGroup.Append className="h-100">
              <Button
                type="submit"
                value="Send"
                className="px-4 py-2 input-dark h-100"
              >
                <Add />
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </form>
      </div>
    </div>
  );
}

export default AdminSettings;
