/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import PageHeader from "../ui/PageHeader";
import AdminUsers from "./AdminUsers";
import AdminMessages from "./AdminMessages";
import AdminSettings from "./AdminSettings";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import { useSelector } from "react-redux";
import { selectCurrentUserIsAdmin } from "../features/session/sessionSlice";

function Admin() {
  const [tabKey, setTabKey] = useState("users");
  const isAdmin = useSelector(selectCurrentUserIsAdmin);

  if (!isAdmin) {
    return (
      <main className="d-flex flex-column">
        <PageHeader additionalText="Admin" />
        <span>Sorry Dave, I can't let you do that</span>
      </main>
    );
  }

  return (
    <main className="admin-tabs h-100 d-flex flex-column">
      <PageHeader additionalText="Admin" />
      <Tabs
        id="admin-tabs"
        activeKey={tabKey}
        onSelect={(k: string | null) => setTabKey(k ?? "users")}
      >
        <Tab eventKey="messages" title="Messages">
          <AdminMessages />
        </Tab>
        <Tab eventKey="users" title="Users">
          <AdminUsers />
        </Tab>
        <Tab eventKey="settings" title="Settings">
          <AdminSettings />
        </Tab>
      </Tabs>
    </main>
  );
}

export default Admin;
