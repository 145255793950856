import io from "socket.io-client";
import feathers, { Paginated, ServiceTypes } from "@feathersjs/feathers";
import socketio from "@feathersjs/socketio-client";
import auth from "@feathersjs/authentication-client";

const { protocol, port, hostname } = window.location;

let rootDomain = hostname;
const hostSplit = hostname.split(".");
if (hostSplit[0] === "www") {
  rootDomain = hostSplit.slice(1).join(".");
}

// const apiPath = `${protocol}//api.${rootDomain}:${port}`;
const apiPath = `${protocol}//${hostname}`;

const socket = io(apiPath, {});

declare module "@feathersjs/feathers" {
  interface ServiceTypes {
    messages: Service<Message>;
    settings: Service<Setting>;
    users: Service<User>;
  }
}

const client = feathers<ServiceTypes>();
client.configure(socketio(socket));
client.configure(auth({ storageKey: "cinemariff-user" }));

export function paginate<T>(data: T | T[] | Paginated<T>): Paginated<T> {
  if (Array.isArray(data)) {
    const arr = data as T[];
    return { total: arr.length, limit: arr.length, skip: 0, data: arr };
  } else if ("data" in data) {
    return data as Paginated<T>;
  } else {
    return { total: 1, limit: 1, skip: 0, data: [data as T] };
  }
}

export async function paginateAsync<T>(
  data: Promise<T | T[] | Paginated<T>>
): Promise<Paginated<T>> {
  return paginate(await data);
}

export default client;
