import React, { useEffect, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import PageHeader from "./PageHeader";
import ChatMessage from "./controls/ChatMessage";
import { RootState } from "../store";
import { createMessage } from "../features/chat/chatSlice";
import ChatInput from "./controls/ChatInput";
import CRC32 from "crc-32";

const mapState = (state: RootState) => ({
  color: state.session.color,
  messages: state.chat.messages,
});

const mapDispatch = {
  createMessage,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

function Chat(props: PropsFromRedux) {
  const [message, setMessage] = useState("");
  const chatMain = useRef<HTMLDivElement>(null);
  // Ref is used here, since it may be mutated without causing a re-render.
  // No need to re-render every time a scroll occurs and this value is updated
  const scrollBottom = useRef<number>(0);

  const sendMessage = () => {
    const text = message.trim();

    if (text) {
      props.createMessage({ text, color: props.color }).then(() => {
        setMessage("");
      });
    }
  };

  useEffect(() => {
    const rescrollToCurrentBottom = () => {
      const chat = chatMain.current;
      if (chat) {
        chat.scrollTop = scrollBottom.current - chat.clientHeight;
      }
    };

    window.addEventListener("resize", rescrollToCurrentBottom);
    return () => {
      window.removeEventListener("resize", rescrollToCurrentBottom);
    };
  }, [scrollBottom]);

  // Scroll to the bottom whenever messages change
  useEffect(() => {
    const chat = chatMain.current;
    if (chat) {
      chat.scrollTop = chat.scrollHeight - chat.clientHeight;
    }
  }, [props.messages]);

  const onChatScroll = (e: React.UIEvent) => {
    let element = e.target as HTMLDivElement;
    scrollBottom.current = element.scrollTop + element.clientHeight;
  };

  const users = Object.fromEntries(props.messages.map((m) => {return  [ m.userName, m.color];}));
  const users2 = Object.entries(users).map((k) => {return {display: k[0], id: `${CRC32.str(k[0])}-${k[1]}`};});

  const onNameClicked = (m: Message): void => { 
    setMessage(message + `@[${m.userName}](${CRC32.str(m.userName)}-${m.color}) `);
  };

  return (
    <main className="h-100 d-flex flex-column">
      <PageHeader />

      <div
        className="p-2 overflow-auto flex-grow-1 bg-dark clear"
        ref={chatMain}
        onScroll={onChatScroll}
      >
        {props.messages
          .slice(Math.max(props.messages.length - 50, 0))
          .map((m) => (
            <ChatMessage key={m.id} message={m} onNameClicked={onNameClicked} />
          ))}
      </div>

      <div>
        <ChatInput
          color={props.color}
          onSendMessage={sendMessage}
          message={message}
          onChange={(e) => setMessage(e.target.value)}
          users={users2}
        />
      </div>
    </main>
  );
}

export default connector(Chat);
