import { Action } from "redux";
import { useDispatch } from "react-redux";
import { configureStore, ThunkAction } from "@reduxjs/toolkit";

import settingsReducer from "../features/settings/settingsSlice";
import chatReducer from "../features/chat/chatSlice";
import sessionReducer from "../features/session/sessionSlice";
import usersReducer from "../features/users/usersSlice";

export const store = configureStore({
  reducer: {
    session: sessionReducer,
    chat: chatReducer,
    settings: settingsReducer,
    users: usersReducer,
  },
});
export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export type AppDispatch = typeof store.dispatch;

export const useThunkDispatch = () => useDispatch<typeof store.dispatch>(); 
