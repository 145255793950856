/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";

interface Props {
  additionalText?: String;
}

class PageHeader extends Component<Props> {
  render() {
    return (
      <div className="p-1" style={{ textAlign: "center" }}>
        <span className="font-title text-primary h2">
          <span className="text-secondary">Cinema</span> Riff{" "}
          {this.props.additionalText}
        </span>
      </div>
    );
  }
}

export default PageHeader;
