/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";

import { RootState } from "../store";
import { connect, ConnectedProps } from "react-redux";
import ChatOverlayMessage from "./controls/ChatOverlayMessage";

const mapState = (state: RootState) => ({
  settings: state.settings.values,
  messages: state.chat.messages,
});

const mapDispatch = {
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>

class ChatOverlay extends Component<PropsFromRedux> {
  chatMain = React.createRef<HTMLElement>();

  // scrollToBottom = () => {
  //   const chat = this.chatMain.current;
  //   if (chat) {
  //     chat.scrollTop = chat.scrollHeight - chat.clientHeight;
  //   }
  // };

  componentDidMount() {
    //client.service("messages").on("created", this.scrollToBottom);
    // this.scrollToBottom();
  }

  componentWillUnmount() {
    // Clean up listeners
    //  client.service("messages").removeListener("created", this.scrollToBottom);
  }

  render() {
    const { messages } = this.props;

    let extraStyles: React.CSSProperties = {};

    let found = this.props.settings["ChatOverlay/css/height"];
    if (found) {
      extraStyles.height = found.value;
    }

    return (
      <main className="chatOverlay" ref={this.chatMain} style={extraStyles}>
        {messages
          .slice(Math.max(messages.length - 20, 0))
          .reverse()
          .map((m) => (
            <ChatOverlayMessage key={m.id} message={m} />
          ))}
      </main>
    );
  }
}

export default connector(ChatOverlay);
