import React, { Component } from "react";
import ColorPicker from "./controls/ColorPicker";
import { ColorResult } from "react-color";
import { Link } from "react-router-dom";

import { setUserColor, logout } from "../features/session/sessionSlice";
import { RootState } from "../store";
import { connect, ConnectedProps } from "react-redux";

const mapState = (state: RootState) => ({
  currentUser: state.session.currentUser,
  color: state.session.color,
});

const mapDispatch = {
  setUserColor,
  logout
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface State {
  menuOpen: boolean;
}

class Navigation extends Component<PropsFromRedux, State> {
  constructor(props: PropsFromRedux) {
    super(props);
    this.state = { menuOpen: false };
  }

  toggleMenu = () => {
    this.setState({ menuOpen: !this.state.menuOpen });
  };

  onEscapeKey = (event: KeyboardEvent) => {
    if (event.keyCode === 27) {
      if (this.state.menuOpen) {
        this.toggleMenu();
      }
    }
  };

  onUserColorChanged = (color: ColorResult) => {
    this.props.setUserColor(color.hex);
  }

  componentDidMount() {
    document.addEventListener("keydown", this.onEscapeKey, false);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.onEscapeKey, false);
  }

  render() {
    const isAdmin = this.props.currentUser
      ? this.props.currentUser.permissions.split(",").includes("admin")
      : false;

    let adminElements: JSX.Element[] = [];
    if (isAdmin) {
      adminElements.push(
        <Link key="admin" to="/admin">
          Admin
        </Link>
      );
      adminElements.push(
        <Link key="chat" to="/">
          Chat
        </Link>
      );
      adminElements.push(
        <Link key="video" to="/video">
          Video
        </Link>
      );
    }
    return (
      <nav role="navigation">
        <div
          id="navMenu"
          className={
            "navMenu " + (this.state.menuOpen ? "checked" : "unchecked")
          }
        >
          <input
            type="checkbox"
            className="inputHitTest"
            onClick={this.toggleMenu}
          />
          <div className="backgroundBlocker" onClick={this.toggleMenu}></div>

          <span className="burgerSlice"></span>
          <span className="burgerSlice"></span>
          <span className="burgerSlice"></span>

          <ul className="menuContent">
            <li>
              <button className="noPad" onClick={this.props.logout}>
                Sign Out
              </button>
            </li>
            <li>
              <div className="flex flex-row">
                <span className="noPad" style={{ paddingRight: "4px" }}>
                  Change Color
                </span>
                <ColorPicker
                  color={this.props.color}
                  onColorChanged={this.onUserColorChanged}
                />
              </div>
            </li>
            {adminElements.map((val, index) => (
              <li key={index}>{val}</li>
            ))}
          </ul>
        </div>
      </nav>
    );
  }
}

export default connector(Navigation);
