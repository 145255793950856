import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import { Provider } from "react-redux";
import { store } from "./store";
import { initSettings } from "./features/settings/settingsSlice";
import { initSession } from "./features/session/sessionSlice";
import { initMessages } from "./features/chat/chatSlice";

store.dispatch(initSession());
store.dispatch(initSettings());
store.dispatch(initMessages());

const rootElement = document.getElementById("root");
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  rootElement
);

// // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
// let vh = window.innerHeight * 0.01;
// // Then we set the value in the --vh custom property to the root of the document
// document.documentElement.style.setProperty('--vh', `${vh}px`);
// console.log("settings size to ", vh);

// window.addEventListener("resize", () => {
//   // We execute the same script as before
//   let vh = window.innerHeight * 0.01;
//   console.log("settings resize to ", vh);
//   document.documentElement.style.setProperty('--vh', `${vh}px`);
// });
